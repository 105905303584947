import { Form, OverlayTrigger, Popover } from 'react-bootstrap';
import { useField } from 'formik';
import AsyncSelect from 'react-select/async';
import InformationIcon from '../../assets/icons/info-bold.svg';
import { useFormikContext } from 'formik';

export default function AsyncSelectField({ label, ...props }) {
  const { data } = props;
  const [field, meta, helper] = useField(props);
  const { setValue } = helper;
  const { values } = useFormikContext();

  const popover = (
    <Popover id="popover-contained">
      <Popover.Header as="h3">{props.popoverContent}</Popover.Header>
    </Popover>
  );

  const setNeuroValue = (option) => {
    if (props.name === 'schoolID') {
      if (option.TelephoneNum && option.SchoolStreet) {
        values.schoolTelephone = option.TelephoneNum;
        values.schoolNurseryAddress = `${option.SchoolStreet}, ${option.SchoolPostCode}`;
        values.schoolNameOther = '';
      } else {
        values.schoolTelephone = '';
        values.schoolNurseryAddress = '';
      }
    }

    setValue(option[props.optionValue]);
  };

  return (
    <Form.Group className="form-input-spacing mt-2" controlId={props.name}>
      {props.popover ? (
        <OverlayTrigger
          trigger={['hover', 'click', 'focus']}
          placement="top"
          overlay={popover}
        >
          <Form.Check.Label className="tool-hover mb-2">
            {label}{' '}
            <img
              src={InformationIcon}
              alt="information-icon"
              className="icon-size"
            />
          </Form.Check.Label>
        </OverlayTrigger>
      ) : (
        <Form.Label>{label}</Form.Label>
      )}

      <AsyncSelect
        value={data.find((x) => x[props.optionValue] === values[props.name])}
        defaultOptions={data.sort((a, b) => {
          if (a[props.optionName] < b[props.optionName]) {
            return -1;
          }
          if (a[props.optionName] > b[props.optionName]) {
            return 1;
          }
          return 0;
        })}
        name={props.name}
        isDisabled={props.disabled}
        loadOptions={(inputValue, callback) => {
          const re = /^\d{1,8}$/; // between 1 and 8 digits
          const reLet = /^[a-zA-Z]{1,3}\d{1,9}$/; //1-3 letters followed by 9 digits
          const reLetShort = /^[a-zA-Z]\d[a-zA-Z][a-zA-Z0-9]{2}$/; // 5 Chrs long, letter, digit, letter, any combo

          if (
            reLetShort.test(inputValue) ||
            re.test(inputValue) ||
            (reLet.test(inputValue) && inputValue.length > 1)
          ) {
            callback(
              data.filter((x) =>
                x[props.optionCode]
                  .toLowerCase()
                  .includes(inputValue.toLowerCase()),
              ),
            );
          } else {
            callback(
              data.filter((x) =>
                x[props.optionName]
                  .toLowerCase()
                  .includes(inputValue.toLowerCase()),
              ),
            );
          }
        }}
        getOptionLabel={(option) => option[props.optionName]}
        getOptionValue={(option) => option[props.optionValue]}
        isSearchable={true}
        maxMenuHeight={150}
        styles={{
          control: (styles) => ({
            ...styles,
            borderColor:
              meta.error && meta.touched ? '#dc3545' : styles.borderColor,
            '&:hover': {
              borderColor:
                meta.error && meta.touched ? '#dc3545' : styles.borderColor,
            },
          }),
        }}
        placeholder={props.placeholder}
        // onChange={(option) => setValue(option[props.optionValue])}
        onChange={(option) => setNeuroValue(option)}
        noOptionsMessage={() => 'No results'}
      />
      {meta.touched && meta.error && (
        <p className="customError">{meta.error}</p>
      )}
    </Form.Group>
  );
}
